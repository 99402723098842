<template>
	<div class="home">
		<div id="content" class="home-content blue-box curved" style="--accent: #00a7e5">
			<svg width="500" height="200" viewBox="0 0 500 200" preserveAspectRatio="none">
				<path d="M0,150 L0,26 Q250,-16 500,26 L500,150 Q250,110 0,150Z" fill="#cee7f0"/>

				<path d="M0,28 L0,24 Q250,-15 500,24 L500,28 Q250,-12 0,28Z" fill="#00a7e5"/>
				<path d="M0,151 L0,147 Q250,108 500,147 L500,151 Q250,111 0,151Z" fill="#00a7e5"/>
			</svg>

            <div class="content-wide centered-banner">
				<p class="banner-title">Encore STAR</p>
            </div>
		</div>

        <div id="content">
			<div v-if="!entryId && result !== 'success'">
				<p class="heading-2">Online Competition 2023</p>

				<img src="@/assets/images/STAR.svg" class="star-poster">
				<img src="@/assets/images/Ensemble-rules.svg" class="star-poster">

				<br>

				<button class="button-round" @click="sectionsPrizes">Sections &amp; Prizes</button>
				<button class="button-round" @click="terms">Terms &amp; Conditions</button>
				
				<p class="info-nospacing"><br></p>

				<p class="heading-2">How to Enter</p>
				<p class="info">
					<ol>
						<li>Read the <span class="info-link info-bold" @click="terms">Terms &amp; Conditions</span>.</li>
						<li>Record your performance.</li>
						<li>Upload your video to social media with the correct hashtags and ensure the video/post is set to public. Teachers, Music Schools and/or parents are all welcome to complete this step. Use the hashtags: #encorestar2023 #encoreonkeys</li>
						<li>Complete the Entry Form, including the link to your performance, and pay the relevant entry fee by midnight 2nd November 2023.</li>
						<li>No late entries will be accepted.</li>
					</ol>
				</p>
				<p class="heading-2">Important Dates</p>
				<p class="info">
					<ul>
						<li><span class="info-bold">Entries open:</span> Sunday 15th October 2023.</li>
						<li><span class="info-bold">Entries close:</span> Sunday 5th November 2023, 11:59pm Brisbane time, QLD, Australia.</li>
						<li><span class="info-bold">Finalists Announced Progressively From:</span> Friday 10th November.</li>
						<li><span class="info-bold">Awards and Prize Presentation:</span> Sunday 19th November (Zoom and Facebook).</li>
					</ul>
				</p>
				<p class="info">* Please ensure that <strong>all</strong> names are submitted on the entry form <strong>exactly</strong> as you would like them to appear on any certificate or award.</p>
				<p class="info">* Submission of an entry is also confirmation that you agree to be bound by the Terms and Conditions of the Encore Star Online Competition.</p>
			</div>

			<div class="form-container" v-if="result !== 'success' && entriesOpen && !entriesClosed">
				<p class="heading-2" v-if="!entryId">To enter, please fill out the form below.</p>
				<p class="heading-2" v-else>Update your details below.</p>

				<div class="bulk-entry-container">
					<div class="bulk-entry-table-header" v-if="!entryId">
						<label for="teacherName" :class="{ 'error': contactNameRequired && showError }">Contact Name</label>
						<input type="text" id="teacherName" v-model="contactName">

						<label for="teacherEmail" :class="{ 'error': contactEmailRequired && showError }">Contact Email</label>
						<input type="text" id="teacherEmail" v-model="contactEmail">

						<label for="confirmEmail" :class="{ 'error': contactEmailsNotMatching && showError }">Confirm Email</label>
						<input type="text" id="confirmEmail" v-model="confirmContactEmail">
					</div>
					<div class="spacer" v-else></div>
					<p class="info">Red cells indicate required fields.</p>
					<br>
					<table class="bulk-entry-table">
						<thead>
							<tr class="bulk-entry-header">
								<th v-if="rows.length > 1 && !entryId"></th>
								<th v-for="(column, i) in columns" :key="i">
									{{ column.name }}
								</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(row, rowIndex) in rows" :key="rowIndex">
								<td v-if="rows.length > 1 && !entryId">
									<span class="remove-button material-icons" @click="removeRow(rowIndex)">delete</span>
								</td>
								<td v-for="(column, index) in columns" :key="index" :class="{ invalid: invalidCells.some(element => {
									return element.index === rowIndex && element.name === column.prop
								})}">
									<p class="no-edit" v-if="(column.name === 'Section' || column.name === 'Level') && entryId">{{ row[column.prop] | sectionName }}</p>
									<select v-else-if="column.columnType === 'select' && column.name === 'Level' && row.section === 'pianoSolo'" v-model="row[column.prop]">
										<option value="JR1">Junior Piano 1</option>
										<option value="JR2">Junior Piano 2</option>
										<option value="JR3">Junior Piano 3</option>
										<option value="JR4">Junior Piano 4</option>
										<option value="PRACC1">Primary/Accent Piano 1</option>
										<option value="PRACC2">Primary/Accent Piano 2</option>
										<option value="ACH1">Achiever Piano 1</option>
										<option value="ACH2">Achiever Piano 2</option>
										<option value="ACH3">Achiever Piano 3</option>
										<option value="ACH4">Achiever Piano 4</option>
									</select>
									<select v-else-if="column.columnType === 'select' && column.name === 'Level' && row.section === 'studentParent'" v-model="row[column.prop]">
										<option value="ALL">Junior/Primary/Accent/Achiever</option>
									</select>
									<select v-else-if="column.columnType === 'select' && column.name === 'Level'" v-model="row[column.prop]">
										<option value="JRPRACC">Junior/Primary/Accent Piano</option>
										<option value="ACH">Achiever Piano</option>
									</select>
									<select v-else-if="column.columnType === 'select' && column.name === 'Section'" v-model="row[column.prop]" @change="sectionChange($event, rowIndex)">
										<option v-for="(opt, i) in column.source" :value="opt.value" :key="i">{{ opt.label }}</option>
									</select>
									<select v-else-if="column.columnType === 'select'" v-model="row[column.prop]">
										<option v-for="(opt, i) in column.source" :value="opt.value" :key="i">{{ opt.label }}</option>
									</select>
									<datepicker v-else-if="column.columnType === 'date'" id="dob" v-model="row[column.prop]" calendar-class="star-date"></datepicker>
									<input v-else-if="column.columnType === 'file'" type="file" @change="previewPhoto($event, rowIndex)">
									<input v-else v-model="row[column.prop]">
								</td>
							</tr>
						</tbody>
					</table>

					<p class="info" v-if="showError">Please make sure all required fields are filled out.</p>

					<div class="button-row">	
						<button class="button-round" @click="addEntry" v-if="!entryId">Add Entry</button>
						<button class="button-round" @click="submit" :disabled="submitPrevent">Submit</button>
					</div>
				</div>
			</div>

			<p class="info-bold" v-else-if="result === 'success'"><br>Thank you for your submission. You will receive a confirmation email with a link allowing you to edit your submission up until the closing date.</p>
			<p class="info-bold" v-else-if="entriesClosed"><br>Entries have closed.</p>
			<p class="info-bold" v-else><br>Entries have not yet open.</p>

			<button class="button-round" v-if="isAdmin" @click="downloadStar">Email Entries</button>
        </div>
	</div>
</template>

<script>
import { storage } from '@/firebase'
import firebase from 'firebase/app'

import Datepicker from 'vuejs-datepicker'

import { functions } from '@/firebase'
import stripe from '@/stripe'

import moment from 'moment'

import { mapState } from 'vuex'

import { v4 as uuidv4 } from 'uuid';

export default {
	props: [
		"result",
		"entryId"
	],
	components: {
		Datepicker,
	},
	data() {
		return {
			contactName: '',
			contactEmail: '',
			confirmContactEmail: '',
			contactNameRequired: true,
			contactEmailRequired: true,
			contactEmailsNotMatching: true,
			submitPrevent: false,
			showError: false,
			sent: false,
			invalidCells: [
				{
					index: 0,
					name: 'studentName'
				}
			],
			columns: [
				{ prop: 'studentName', name: 'Student Name' },
				{ prop: 'dob', name: 'Student Date of Birth', columnType: 'date'},
				{ prop: 'musicSchool', name: 'Music School (if applicable)'},
				{ prop: 'teacherName', name: 'Teacher Name'},
				{ prop: 'teacherEmail', name: 'Teacher Email'},
				{ prop: 'country', name: 'Country'},
				{ prop: 'section', name: 'Section', columnType: 'select',
					source: [
						{ value: 'pianoSolo', label: 'Piano Solo' },
						{ value: 'pianoDuet', label: 'Piano Duet' },
						{ value: 'soloPianoDuet', label: 'Solo Piano Duet' },
						{ value: 'soloEnsemble', label: 'Solo Ensemble' },
						{ value: 'groupEnsemble', label: 'Group Ensemble' },
						{ value: 'studentParent', label: 'Student/Parent Performance' }
					]},
				{ prop: 'level', name: 'Level', columnType: 'select' },
				{ prop: 'improvisation', name: 'Includes Improvisation', columnType: 'select',
					labelKey: 'label',
					valueKey: 'value',
					source: [
						{ value: "true", label: 'Yes' },
						{ value: "false", label: 'No' },
				]},
				{ prop: 'title', name: 'Title'},
				{ prop: 'link', name: 'Video Link'},
				{ prop: 'photo', name: 'Photo', columnType: 'file' },
				{ prop: 'groupName', name: 'Group Name (if applicable)'},
				{ prop: 'coperformer', name: 'Co-performers - separate with commas (if applicable)'},
			],
			rows: [],
		}
	},
	methods: {
		addEntry() {
			this.rows.push({
				// studentName: "James",
				// dob: new Date(2000, 1, 1),
				// musicSchool: "e",
				// teacherName: "a",
				// teacherEmail: "a@a.com",
				// country: "a",
				// section: "pianoSolo",
				// level: "JR1",
				// improvisation: "false",
				// title: "a",
				// groupName: "a",
				// link: "a",
				// coperformer: "James, William",
				studentName: "",
				dob: new Date(2000, 1, 1),
				musicSchool: "",
				teacherName: "",
				teacherEmail: "",
				country: "",
				section: "pianoSolo",
				level: "JR1",
				improvisation: false,
				title: "",
				groupName: "",
				link: "",
				coperformer: "",
				photo: null
			});
		},
		removeRow(index) {
			if (this.rows.length > 1) {
				this.rows.splice(index, 1);
			}
		},
		terms() {
			this.downloadFile("Encore Star 2023 - Terms_Conditions.pdf")
		},
		sectionsPrizes() {
			this.downloadFile("Encore Star 2023 - Sections Prizes.pdf")
		},
		sectionChange(event, index) {
			if (event.target.value === "pianoSolo") {
				this.rows[index].level = "JR1"
			} else if (event.target.value === "studentParent") {
				this.rows[index].level = "ALL"
			} else {
				this.rows[index].level = "JRPRACC"
			}
		},
		downloadFile(file) {
			const storageRef = storage.ref(`${file}`);
			storageRef.getDownloadURL().then(url => {
				window.open(url, "_self");
			}).catch(err => {
				console.error(err);
			})
		},
		validateForm() {
			let valid = this.invalidCells.length === 0 && (!(this.contactNameRequired || this.contactEmailRequired || this.contactEmailsNotMatching) || this.entryId);

			this.showError = !valid;
			return valid;
		},
		validEmail(email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		submit() {
			if (this.submitPrevent) {
				return;
			}

			this.submitPrevent = true;

			if (!this.validateForm()) {
				this.submitPrevent = false;
				return;
			}

			var sendForm = [];

			Promise.all(this.rows.map(row => {
				row.dob = row.dob.toString();
				row.coperformer = row.coperformer.split(",").map(t => t.trim())
				
				// Upload the photo
				// Need to gen a unique id
				var photo = row.photo;

				if ('original' in photo) {
					sendForm.push(row);
				} else {
					var id = uuidv4();
					var fileName = id + '.' + photo.name.slice((photo.name.lastIndexOf(".") - 1 >>> 0) + 2);
					
					var storageRef = storage.ref(`starPhotos/${fileName}`);
	
					row.photo = {
						'fname': fileName,
						'original': photo.name
					};
	
					sendForm.push(row);
	
					// Upload the row's photo to storage
					return new Promise((resolve, reject) => {
						storageRef.put(photo).on('state_changed', () => {
						}, reject, resolve)
					});
				}
			})).then(() => {
				if (this.entryId) {
					var updateFunction = functions.httpsCallable('updateStar');
				
					updateFunction({
						entryId: this.entryId,
						form: sendForm
					}).then(() => {
						this.sent = true;
						this.result = 'success'
					})
				} else {
					var checkoutFunction = functions.httpsCallable('starEntryForm');
	
					checkoutFunction({
						contactName: this.contactName,
						contactEmail: this.contactEmail,
						form: sendForm
					}).then(result => {
						return result.data
					}).then(data => {
						if (!data.success) {
							throw 'Entries have closed.'
						}
	
						return stripe.redirectToCheckout({
							sessionId: data.id
						})
					}).then(result => {
						if (result.error) {
							alert(result.error.message)
						}
					}).catch(error => {
						console.error("Error: ", error)
					})
				}
			})
		},
		downloadStar() {
			console.log("Downloading")
			functions.httpsCallable('starExcel')().then((data) => {
				console.log(data)
				this.$toast.open("Done")
			});
		},
		previewPhoto(event, index) {
			if (event.target.files[0] === undefined) {
				this.rows[index]["photo"] = null;
			} else {
				this.rows[index]["photo"] = event.target.files[0];
			}
		}
	},
	filters: {
		sectionName(val) {
			return {
				pianoSolo: 'Piano Solo',
				pianoDuet: 'Piano Duet',
				soloPianoDuet: 'Solo Piano Duet',
				groupEnsemble: 'Group Ensemble',
				soloEnsemble: 'Solo Ensemble',
				studentParent: 'Student/Parent Performance',
				JRPRACC: 'Junior/Primary/Accent Piano',
				ACH: 'Achiever Piano',
				ALL: 'Junior/Primary/Accent/Achiever',
				JR1: 'Junior Piano 1',
				JR2: 'Junior Piano 2',
				JR3: 'Junior Piano 3',
				JR4: 'Junior Piano 4',
				PRACC1: 'Primary/Accent Piano 1',
				PRACC2: 'Primary/Accent Piano 2',
				ACH1: 'Achiever Piano 1',
				ACH2: 'Achiever Piano 2',
				ACH3: 'Achiever Piano 3',
				ACH4: 'Achiever Piano 4'
			}[val];
		}
	},
	computed: {
		entriesOpen() {
			let startDate = moment('2023-10-15T00:00:00+0700');
			let today = moment();
			return today.isAfter(startDate);
			// return true;
		},
		entriesClosed() {
			let finishDate = moment('2023-11-06T00:00:00+0700');
			let today = moment();
			return today.isSameOrAfter(finishDate);
		},
		...mapState('user', ['isAdmin'])
	},
	mounted() {
		if (this.entryId) {
			functions.httpsCallable('starData')({ entryId: this.entryId }).then(data => {
				data.data.forEach(entry => {
					entry.dob = (new firebase.firestore.Timestamp(entry.dob._seconds, entry.dob._nanoseconds)).toDate()
					entry.coperformer = entry.coperformer.join(", ")
				})
				this.rows = data.data
			})
		} else {
			this.addEntry()
		}
	},
	watch: {
		rows: {
			deep: true,
			handler(val) {
				this.invalidCells = [];

				let validationFunctions = {
					studentName: (row, key) => row[key] === "",
					email: (row, key) => !this.validEmail(row[key]),
					teacherName: (row, key) => row[key] === "",
					teacherEmail: (row, key) => !this.validEmail(row[key]),
					country: (row, key) => row[key] === "",
					title: (row, key) => row[key] === "",
					coperformer: (row, key) => (row[key] === '') && (row.section == 'pianoDuet' || row.section == 'groupEnsemble' || row.section == 'studentParent'),
					groupName: (row, key) => (row[key] === '') && (row.section == 'groupEnsemble' || row.section == 'studentParent'),
					link: (row, key) => row[key] === "",
					photo: (row, key) => row[key] === null,
				}

				val.forEach((row, index) => {
					Object.keys(row).forEach(key => {
						if (Object.keys(validationFunctions).includes(key)) {
							if (validationFunctions[key](row, key)) {
								this.invalidCells.push({ index: index, name: key })
							}
						}
					})
				});
			}
		},
		contactName: function(val) {
			this.contactNameRequired = val === "";
		},
		contactEmail: function(val) {
			this.contactEmailRequired = !this.validEmail(val);
		},
		confirmContactEmail: function(val) {
			this.contactEmailsNotMatching = val !== this.contactEmail
		},
	},
}
</script>
